import { createContext, useState } from "react";
import { QuestionData } from '../components/QuestionData';

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
  const [questionData, setQuestionData] = useState(QuestionData.questions); //.slice(0, 28) .filter(Obj=> Obj.is_score)
  const [scoreData, setScoreData] = useState(QuestionData.score);   //Question Score Information
  const [currentQuestionID, setCurrentQuestionID] = useState();     // Current Question ID  

  const questionDataLength = questionData.length;                   //Question Length

  const progressValue = 100 / questionDataLength;                   // Initial Progress Value 
  const [progressBar, setProgrssBar] = useState(progressValue);     // Current Progress Value

  const [apiResponseData, setApiResponseData] = useState([]);       // API Response State

  const [isNavigateScore, setIsNavigateScore] = useState(false);    // Navigate to Score state
  const [isResume, setIsResume] = useState(false);                  // Resume State
  const [isResumeNone, setIsResumeNone] = useState(false);          // Resume State

  const scoreSubtext = {
    activity_score: [
      "Beginning your journey in activity! 🚶",
      "Gaining momentum in activity! 🏃",
      "Mastering the fitness stride! 🏋️",
      "Excelling in consistent movement! 🚴",
      "At the pinnacle of physical prowess! 🌟",
    ],
    nutrition_score: [
      "Beginning your journey in nutrition! 🍏",
      "Gaining momentum in balanced meals! 🥗",
      "Mastering the art of mindful eating! 🍱",
      "Excelling in nutritional choices! 🍓",
      "At the pinnacle of healthy eating! 🌟",
    ],
    sleep_score: [
      "Beginning your journey in rest! 🌙",
      "Gaining momentum in deeper sleep! 💤",
      "Mastering the rhythm of rejuvenation! 🛌",
      "Excelling in consistent restful nights! 🌞",
      "At the pinnacle of sleep mastery! 🌟",
    ],
    mental_health_score: [
      "Beginning your journey in mental wellness! 🌱",
      "Gaining momentum in mindfulness! 🍃",
      "Mastering emotional equilibrium! 🌊",
      "Excelling in mental serenity! 🌈",
      "At the pinnacle of mental harmony! 🌟",
    ],
  };

  return (
    <DataContext.Provider
      value={{
        questionData,
        setQuestionData,
        scoreData,
        setScoreData,
        apiResponseData,
        setApiResponseData,
        currentQuestionID,
        setCurrentQuestionID,
        progressBar,
        setProgrssBar,
        progressValue,
        questionDataLength,
        scoreSubtext,
        isNavigateScore,
        setIsNavigateScore,
        isResume,
        setIsResume,
        isResumeNone, 
        setIsResumeNone
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;