import React, { useContext, useEffect, useState } from 'react'
import { Form } from "react-bootstrap";
import DataContext from '../context/DataContext';
import { animateScroll as scroll } from 'react-scroll';

// import { add, format } from 'date-fns';

const Questions = ({isVisible, setNextStatus, handleCallAPI}) => {

  const {
    questionData,
    setQuestionData,
    currentQuestionID,
    isResume,
    isResumeNone
  } = useContext(DataContext);


  const [currentQuestion, setCurrentQuestion] = useState([]);

  const [selectedTime, setSelectedTime] = useState(""); //Time Picker 

  //Radio options onChange
  const handleRadioInputChange = (currentSelection) =>{

    setCurrentQuestion({
      ...currentQuestion,
      data: { value: currentSelection},
    });

    setQuestionData(
      questionData.map((question, index) => {
        if (index === currentQuestionID) {
          return { ...question, data: { value: currentSelection}, };
        }
        return question;
      })
    );

    setNextStatus(true);
  }

  //Checkbox options onChange
  const handleCheckboxInputChange = (isChecked, currentSelection) => {

    let updatedValues = [];
    
    if(currentQuestion.is_none){
      console.log(isResume)
      currentQuestion.is_none = false
    }

    if(currentSelection !== "None"){
      updatedValues = isChecked
      ? [...currentQuestion.data.value, currentSelection]
      : currentQuestion.data.value.filter(val => val !== currentSelection);
    }
      
    const updatedQuestionData = questionData.map((question, index) => {
      if (index === currentQuestionID) {
        return { ...question, data: { value: updatedValues } };
      }
      return question;
    });
  
    setCurrentQuestion({
      ...currentQuestion,
      data: { value: updatedValues },
    });
  
    setQuestionData(updatedQuestionData);

    if(currentQuestion.is_required){
      setNextStatus(updatedValues.length > 0);
    }else{
      setNextStatus(true)
    }
    
  };

  //TimePicker onChange  
  const handleTimePickerChange = (updatedTime) => {

    if (!validateTime(updatedTime)) {
      updatedTime = questionData[currentQuestionID].is_add ? "06:00" : "22:00";
    }
    
    const timePickerValue = updatedTime;
    const [hours, minutes] = timePickerValue.split(":");

    const convertedTime = convertTime(hours, minutes, questionData[currentQuestionID].is_add)

    setSelectedTime(timePickerValue);
    // setSelectedTime(formatTimeToAMPM(timePickerValue));

    setCurrentQuestion({
      ...currentQuestion,
      data: { value: convertedTime },
    });

    setQuestionData(
      questionData.map((question, index) => {
        if (index === currentQuestionID) {
          return { ...question, data: { value: convertedTime } };
        }
        return question;
      })
    );
    setNextStatus(true);
  };

  const validateTime = (timeString) => {
    const timeRegex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
    return timeRegex.test(timeString);
  };

  //Convert to ISO
  const convertTime = (hour, minute, addStatus) => {
    const currentDate = new Date();
    const targetTime = new Date(currentDate);
  
    if (addStatus) {
      targetTime.setDate(currentDate.getDate() + 1);
    }
  
    targetTime.setHours(Number(hour), 0, 0, 0);
    targetTime.setMinutes(Number(minute), 0, 0, 0);
  
    const year = targetTime.getUTCFullYear();
    const month = String(targetTime.getUTCMonth() + 1).padStart(2, "0");
    const day = String(targetTime.getUTCDate()).padStart(2, "0");
    const hours = String(targetTime.getUTCHours()).padStart(2, "0");
    const minutes = String(targetTime.getUTCMinutes()).padStart(2, "0");
    const seconds = String(targetTime.getUTCSeconds()).padStart(2, "0");
  
    const formattedTargetTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
  
    return formattedTargetTime;
  };

  //convert to AM/PM
  function formatTimeToAMPM(time) {
    const [hours, minutes] = time.split(':');
    let period = 'AM';
    let formattedHours = parseInt(hours, 10);
  
    if (formattedHours >= 12) {
      period = 'PM';
      if (formattedHours > 12) {
        formattedHours -= 12;
      }
    }
  
    return `${formattedHours}:${minutes} ${period}`;
  }

  //Next Question Change UseEffect
  useEffect(()=>{

    scrollToTop()

    let currentQuestion = questionData[currentQuestionID];

    if(currentQuestion){
      if(currentQuestion.is_required){
        if(currentQuestion?.is_boolean){
          setNextStatus((currentQuestion?.data?.value).toString());
        }else{
          setNextStatus((currentQuestion?.data?.value?.length || 0) > 0);
        }
      }else if(currentQuestion.is_none){
        setNextStatus(false);

        if(currentQuestion.is_saved){
          setNextStatus(true);
        }

      }else{
        setNextStatus(true)
      }
  
      setCurrentQuestion(currentQuestion)
  
      if (currentQuestion.input_type === "timepicker") {
        const { data } = currentQuestion;
      
        if (data?.value?.length && data.value) {        
          const dateTime = new Date(data.value);
          const convertedTime = String(dateTime.getHours()).padStart(2, "0") + ":"+ String(dateTime.getMinutes()).padStart(2, "0");
          
          setSelectedTime(convertedTime); //24 Hour Format conversion
        } else {
          //timePicker Else
          
          let tempTime = currentQuestion.default_value || (currentQuestion.sub_type === "bed_time_goal" ? "22:00" : "06:00")
          setSelectedTime(tempTime);

          const [hours, minutes] = tempTime.split(":");

          setCurrentQuestion((prevData) => ({
            ...prevData,
            data: {
              value: convertTime(hours, minutes,currentQuestion.is_add),
            },
          }));

          const updatedQuestionData = questionData.map((question, index) => {
            if (question.id === (currentQuestion.id)) {
              return {
                ...question,
                data: { value: convertTime(hours, minutes,currentQuestion.is_add) },
              };
            }
            return question;
          });
          setQuestionData(updatedQuestionData);
          
          //setNextStatus(true)
        }
        // if(currentQuestion.is_saved){
        //   setNextStatus(true);
        // }
      }
    }else{
      if(questionData.length === currentQuestionID){
        handleCallAPI()
      }      
    }
  },[currentQuestionID])

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 10, 
      smooth: 'easeInOutQuart',
    });
  };

  return (
    <div className={`question-block ${isVisible ? "fade-in" : "fade-out"}`}>
      <section className="top-section">
        {currentQuestion && currentQuestion.heading && (
          <img
            src={require(`../assets/img/${currentQuestion.heading.replace(
              /\s/g,
              ""
            )}${currentQuestion.is_alt_icon ? "-alt" : ""}-Icon.png`)}
            alt="Questionnaire-icon"
            className="question-icon"
          />
        )}
        <h4 className="question-type">{currentQuestion.heading}</h4>
      </section>
      <section className="question-section">
        <h4 className="question-head">{currentQuestion.question}</h4>
        <h6 className="question-desc">{currentQuestion.question_desc}</h6>

        <div className="questions-options">
          {currentQuestion.input_type === "timepicker" && (
            <div>
              <input
                type="time"
                id="timePicker"
                name="timePicker"
                value={selectedTime}
                className="time-picker-input"
                onClick={(event) => {
                  handleTimePickerChange(event.target.value);
                }}
                onChange={(event) => {
                  handleTimePickerChange(event.target.value);
                }}
              />
            </div>
          )}

          {currentQuestion.input_type !== "timepicker" &&
            currentQuestion?.options?.map((option, index) => (
              <Form.Check
                key={index}
                type={currentQuestion.input_type}
                id={`${currentQuestion.id}-option-${index}`}
                className={`${
                  (
                    currentQuestion.input_type === "checkbox"
                      ? currentQuestion.data?.value.includes(option.value) ||
                        (option.value === "None" &&
                          (!currentQuestion.is_none || isResumeNone) &&
                          currentQuestion.data?.value.length === 0)
                      : (currentQuestion.data?.value).toString() ===
                        option.value
                  )
                    ? "selected"
                    : ""
                } ${
                  !option.label.split(" : ")[1] ? "single-line" : "double-line"
                } ${
                  currentQuestion?.is_option_based === true
                    ? option?.allowedValues?.includes(
                        currentQuestion?.parent_answer
                      )
                      ? "show"
                      : "hide"
                    : ""
                } `}
              >
                <Form.Check.Input
                  type={currentQuestion.input_type}
                  name={
                    currentQuestion.input_type === "radio"
                      ? "radioOption"
                      : "checkboxOption"
                  }
                  className={
                    currentQuestion.input_type === "radio"
                      ? "radioOption"
                      : "checkboxOption"
                  }
                  value={option.value}
                  checked={
                    currentQuestion.input_type === "checkbox"
                      ? currentQuestion.data?.value.includes(option.value) ||
                        (option.value === "None" &&
                          (!currentQuestion.is_none || isResumeNone) &&
                          currentQuestion.data?.value.length === 0)
                      : (currentQuestion.data?.value).toString() ===
                        option.value
                  }
                  onChange={(event) => {
                    const value = event.target.value;
                    const isChecked = event.target.checked;

                    // if (currentQuestion.is_score) {
                    //   calculateScore(currentQuestion.heading, option.score);
                    // }

                    if (currentQuestion.input_type === "radio") {
                      handleRadioInputChange(value);
                    } else {
                      handleCheckboxInputChange(isChecked, value);
                    }
                  }}
                />
                <Form.Check.Label>
                  {/* <img
                    src={require("../assets/img/icon-2.png")}
                    alt="questionnaire-icon"
                    className="question-options-icon"
                  /> */}
                  {option.icon && (
                    <img
                      src={require(`../assets/img/option-icons/${option.icon}.png`)}
                      alt="questionnaire-icon"
                      className="question-options-icon"
                      loading="eager"
                    />
                    // <OptionIcon icon={option.icon}/>
                  )}
                  <span className="main">
                    {option.label.split(" : ")[0]}
                    {option.label.split(" : ")[1] && (
                      <span className="sub">
                        <br />
                        {option.label.split(" : ")[1]}
                      </span>
                    )}
                  </span>
                </Form.Check.Label>
              </Form.Check>
            ))}
        </div>
      </section>
    </div>
  );
};

export default Questions