import './App.css';
import './assets/css/base.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './components/Home';
import ScoreSnapshot from './components/ScoreSnapshot';
import DataContext from './context/DataContext';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';

function App() {

  const [accessToken, setAccessToken] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [userGender, setUserGender] = useState("male");

  const isAndroid = /android/.test(navigator.userAgent.toLowerCase());
  const isIOS = /iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase());

  const {
    questionData,
    setQuestionData,
    setCurrentQuestionID,
    setIsNavigateScore,
    setIsResume,
    setIsResumeNone
  } = useContext(DataContext);

  const [isQuitLoader, setIsQuitLoader] = useState(true);

  const {REACT_APP_BASE_URL, REACT_APP_CLIENT_ID} = process.env; // REACT_APP_ACCESS_TOKEN

  //Getting Data from Native
  const loadAccessToken = (token, timeZone, gender="male", isResumeState=false) => {
    console.log("Access Token Received")
    setAccessToken(token);
    setTimeZone(timeZone);
    setUserGender(gender);

    console.log(token, timeZone, gender,"-", isResumeState)
    
    const isResume = typeof isResumeState === "string" ? isResumeState.toLowerCase() === "true" : isResumeState;
    console.log("isResume",isResume)

    setIsResume(isResume);

    if(isResume){
      getQuestionnaire(token, timeZone); // get Answers for all Questionnaires
    }else{
      setCurrentQuestionID(0);
      setIsQuitLoader(false);
    }
    
  }

  //Unlock Plan
  const unlockPlanResponse = () => {

    /* Integration Update */

    if (isAndroid) {

      window.sendResponseToAndroidWebView();

    } else if (isIOS) {

      const webViewRef = window.webkit?.messageHandlers.goal;
      if (webViewRef) {
        webViewRef.postMessage({
          status: "ok",
        });
      } else {
        console.error("WebView not found");
      }

    } else {
      console.log("OS Platform not Found");
    }

  };

  //Android-Integration
  window.sendResponseToAndroidWebView = () => {
    window.Android.unlockPremium("true");
    return "true";
  };

  //Deny Plan
  const denyPlanResponse = () => {
    /* Integration Update */

    if (isAndroid) {
      window.sendDenyResponseToWebView();
    } else if (isIOS) {
      const webViewRef = window.webkit?.messageHandlers.goalBack;
      if (webViewRef) {
        webViewRef.postMessage({
          status: "ok",
        });
      } else {
        console.error("WebView not found");
      }
    } else {
      console.log("OS Platform not Found");
    }
  };

  //Android-Integration
  window.sendDenyResponseToWebView = () => {
    window.Android.denyPremium("true");
    return "true";
  };

  //Global Access - Declaration
  useEffect(() => {
    window.loadAccessToken = loadAccessToken;
    window.unlockPlanResponse = unlockPlanResponse;
  }, []);

  useEffect(()=>{ },[accessToken])

  //Get Questionnaire if it's Resume State
  const getQuestionnaire = (userToken, userTimeZone) => {

    setIsQuitLoader(true);
    
    const apiURL = REACT_APP_BASE_URL;

    const headers = {
      client_id: REACT_APP_CLIENT_ID,
      access_token: userToken, //REACT_APP_ACCESS_TOKEN, accessToken,
      user_timezone: userTimeZone, //"Asia/Kolkata",  //Intl.DateTimeFormat().resolvedOptions().timeZone
      "Content-Type": "application/json",
    };

    // Axios Request API
    axios
      .get(`${apiURL}/v2/ai-coach/program/goal`, { headers })
      .then(function (response) {
        let responseData = response.data.data;

        if (responseData) {
          const nestedObjects = Object.values(responseData);
          const updatedData = Object.assign({}, ...nestedObjects);

          //Bind Answers
          const questionResponse = questionData.map((question, index) => {
            let dataValue = "";

            const filterQuestion = updatedData[question.sub_type];

            if (question.is_boolean) {
              dataValue = filterQuestion.toString();
            } else if (question.input_type === "radio") {
              dataValue = filterQuestion?.toString() || "";
            } else {
              dataValue = filterQuestion || "";
            }

            if (question.is_remove && question.is_change_subtype) {
              dataValue = updatedData['bed_time_goal']
                ? "Bedtime"
                : "Waketime";
            }

            return {
              ...question,
              data: { value: dataValue },
              is_saved: true,
            };
          });
          setQuestionData(questionResponse);
        }
        
        setCurrentQuestionID(0);
        setIsResume(true);
        setIsResumeNone(true);    //Is None Question Resume
        setIsNavigateScore(true); // Navigate to Score Page
        setIsQuitLoader(false);   // Quit Loader
      })
      .catch(function (error) {
        console.error("Error GET request:", error);

        setCurrentQuestionID(0);
        setIsResume(false);
        setIsNavigateScore(false); // Testing
        setIsQuitLoader(false);   // Testing
        
      });

  };

  //Initial State Set
  useEffect(()=>{
    // setIsQuitLoader(false);
    // setCurrentQuestionID(0);
  },[])
  
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Home
                accessToken={accessToken}
                timeZone={timeZone}
                userGender={userGender}
                isQuitLoader={isQuitLoader}
                denyPlanResponse ={denyPlanResponse}
              />
            }
          />
          <Route
            path="/score"
            element={
              <ScoreSnapshot
                unlockPlanResponse={unlockPlanResponse}
                denyPlanResponse ={denyPlanResponse}
                userGender={userGender}
              />
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;