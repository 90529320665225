import React, { useContext, useEffect, useState } from 'react'
import { Button, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faClose, faRefresh } from "@fortawesome/free-solid-svg-icons";
import Questions from './Questions';
import DataContext from '../context/DataContext';
import { useNavigate } from 'react-router';
// import preloaderGif from "../assets/img/api-loader.gif";
// import preloaderPlay from "../assets/img/loader-play.gif";
// import preloaderPaused from "../assets/img/loader-pause.gif";
import Lottie from 'react-lottie';
import apiLoaderPlayData from '../assets/img/loader-play.json'; 
import androidPreloaderData from '../assets/img/android-pre-loader.json'; 
import axios from 'axios';

const Home = ({
  accessToken,
  timeZone,
  userGender,
  isQuitLoader,
  denyPlanResponse
}) => {
  const { REACT_APP_BASE_URL, REACT_APP_CLIENT_ID } = process.env; //REACT_APP_ACCESS_TOKEN

  const navigate = useNavigate();

  const {
    questionData,
    setQuestionData,
    setApiResponseData,
    scoreData,
    currentQuestionID,
    setCurrentQuestionID,
    progressBar,
    setProgrssBar,
    progressValue,
    questionDataLength,
    scoreSubtext,
    isNavigateScore
  } = useContext(DataContext);

  const [isVisible, setIsVisible] = useState(true); //Question - Animation
  const [nextStatus, setNextStatus] = useState(false); //Next - Enable/Disable

  const [apiRequestStatus, setApiRequestStatus] = useState(false); //API Request Status

  
  /* Integration */
  const isAndroid = /android/.test(navigator.userAgent.toLowerCase());
  const isIOS = /iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase());


  //Prev/Next Button Click
  const handleButtonClick = (updatedValue) => {
    let constCheckQuestion = 0;

    constCheckQuestion = currentQuestionID + updatedValue;

    if (
      questionData[constCheckQuestion].parent_key &&
      !questionData[constCheckQuestion].is_option_based
    ) {
      const filterQuestion = questionData.find(
        (data) =>
          parseInt(data.id) ===
          parseInt(questionData[constCheckQuestion].parent_key)
      );

      const filterValue = filterQuestion.data?.value;

      if (
        !questionData[constCheckQuestion].allowedValues.includes(filterValue)
      ) {
        updatedValue = updatedValue + updatedValue;

        const updatedQuestionData = questionData.map((question, index) => {
          if (index === constCheckQuestion) {
            return {
              ...question,
              data: { value: question.input_type !== "checkbox" ? null : [] },
            };
          }
          return question;
        });

        setQuestionData(updatedQuestionData);
      }
    } else if (questionData[constCheckQuestion].is_gender) {
      if (
        !questionData[constCheckQuestion].allowedValues.includes(userGender)
      ) {
        updatedValue = updatedValue + updatedValue;

        const updatedQuestionData = questionData.map((question, index) => {
          if (index === constCheckQuestion) {
            return {
              ...question,
              data: { value: false },
            };
          }
          return question;
        });
        setQuestionData(updatedQuestionData);
      }
    } else if (questionData[constCheckQuestion].is_option_based) {
      let parentQnAnswer = questionData.find(
        (data) =>
          parseInt(data.id) ===
          parseInt(questionData[constCheckQuestion].parent_key)
      )?.data?.value;

      const updatedQuestionData = questionData.map((question, index) => {
        if (index === constCheckQuestion) {
          return {
            ...question,
            parent_answer: parentQnAnswer,
          };
        }
        return question;
      });
      setQuestionData(updatedQuestionData);
    }

    setIsVisible(false);

    setTimeout(() => {
      setIsVisible(true);
      setProgrssBar(progressBar + progressValue * updatedValue);
      setCurrentQuestionID(currentQuestionID + updatedValue);
      // const questionnaireBlock = document.querySelector(".questionnaire-block");
      // if (questionnaireBlock) {
      //   questionnaireBlock.scrollIntoView({ behavior: "smooth", inlineToType: "nearest" });
      // }
    }, 300);
  };

  const scoreCalculation = (scoreResponse) => {
    let scoreObject = scoreResponse.map((data, dataIndex) => {
      let questionScore = data.data.value || 0;
      let questionCount = data.questionCount || 0;

      // let progressValue = questionCount > 0  ? ( (100/(questionCount * 5)) * questionScore) : 0;
      let progressStatus = 1;

      if (data.sub_type === "activity_score" && questionScore >= 5) {
        progressStatus =
          questionScore >= 18       //Peak
            ? (progressStatus = 5)
            : questionScore >= 15   //Thriving
            ? (progressStatus = 4)
            : questionScore >= 12   //Advancing
            ? (progressStatus = 3)
            : questionScore >= 10   //Progressing
            ? (progressStatus = 2)
            : (progressStatus = 1); // Starting
      } else if (data.sub_type === "nutrition_score" && questionScore >= 5) {
        progressStatus =
          questionScore >= 14       //Peak
            ? (progressStatus = 5)
            : questionScore >= 12   //Thriving
            ? (progressStatus = 4)
            : questionScore >= 10   //Advancing
            ? (progressStatus = 3)
            : questionScore >= 8    //Progressing
            ? (progressStatus = 2)
            : (progressStatus = 1); // Starting
      } else if (data.sub_type === "sleep_score" && questionScore >= 5) {
        progressStatus =
          questionScore >= 18       //Peak
            ? (progressStatus = 5)
            : questionScore >= 15   //Thriving
            ? (progressStatus = 4)
            : questionScore >= 13   //Advancing
            ? (progressStatus = 3)
            : questionScore >= 11   //Progressing
            ? (progressStatus = 2)  
            : (progressStatus = 1); // Starting
      } else if (
        data.sub_type === "mental_health_score" &&
        questionScore >= 1
      ) {
        progressStatus =
          questionScore >= 10       //Peak
            ? (progressStatus = 5)
            : questionScore >= 8    //Thriving
            ? (progressStatus = 4)
            : questionScore >= 6    //Advancing
            ? (progressStatus = 3)
            : questionScore >= 3    //Progressing
            ? (progressStatus = 2)
            : (progressStatus = 1); // Starting
      }

      return {
        id: dataIndex + 1,
        name: data.heading,
        score: questionScore,
        questionCount: questionCount,
        progress_value: parseFloat(progressStatus) * 20, // parseFloat(progressValue).toFixed(2) || 0,
        progress_status: progressStatus,
        progress_content:
          scoreSubtext[data.sub_type][parseInt(progressStatus) - 1],
      };
    });
    return scoreObject;
  }

  //API Request
  const handleCallAPI = async () => {
    const apiURL = REACT_APP_BASE_URL;

    const scoreResponse = scoreData.map((question, index) => {
      const headingWiseData = questionData.filter(
        (val) => val.heading === question.heading && val.is_score
      );

      const dataScore = headingWiseData.reduce((acc, qn) => {
        const answerValue = qn.data?.value;
        const score = (qn?.options).find(
          (level) => level.value === answerValue
        )?.score;
        return acc + parseFloat(score || 0);
      }, 0);

      return {
        ...question,
        data: {
          value: dataScore,
        },
        questionCount: headingWiseData?.length,
        type: question.type,
        sub_type: question.sub_type,
      };
    });

    const questionResponse = questionData
      .filter((val) => !val.is_remove)
      .map((question) => {
        if (question.is_boolean) {
          question.data.value = question.data.value === "true";
        }
        return {
          data: question.data,
          type: question.type,
          sub_type: question.sub_type,
        };
      });

    const finalResponse = questionResponse.concat(scoreResponse); //combined Objects
    //Calculation Process

    // const scoreProgressObject = scoreResponse.map((data, dataIndex) => {
    //   let questionScore = data.data.value || 0;
    //   let questionCount = data.questionCount || 0;

    //   // let progressValue = questionCount > 0  ? ( (100/(questionCount * 5)) * questionScore) : 0;
    //   let progressStatus = 1;

    //   if (data.sub_type === "activity_score" && questionScore >= 5) {
    //     progressStatus =
    //       questionScore >= 18       //Peak
    //         ? (progressStatus = 5)
    //         : questionScore >= 15   //Thriving
    //         ? (progressStatus = 4)
    //         : questionScore >= 12   //Advancing
    //         ? (progressStatus = 3)
    //         : questionScore >= 10   //Progressing
    //         ? (progressStatus = 2)
    //         : (progressStatus = 1); // Starting
    //   } else if (data.sub_type === "nutrition_score" && questionScore >= 5) {
    //     progressStatus =
    //       questionScore >= 14       //Peak
    //         ? (progressStatus = 5)
    //         : questionScore >= 12   //Thriving
    //         ? (progressStatus = 4)
    //         : questionScore >= 10   //Advancing
    //         ? (progressStatus = 3)
    //         : questionScore >= 8    //Progressing
    //         ? (progressStatus = 2)
    //         : (progressStatus = 1); // Starting
    //   } else if (data.sub_type === "sleep_score" && questionScore >= 5) {
    //     progressStatus =
    //       questionScore >= 18       //Peak
    //         ? (progressStatus = 5)
    //         : questionScore >= 15   //Thriving
    //         ? (progressStatus = 4)
    //         : questionScore >= 13   //Advancing
    //         ? (progressStatus = 3)
    //         : questionScore >= 11   //Progressing
    //         ? (progressStatus = 2)  
    //         : (progressStatus = 1); // Starting
    //   } else if (
    //     data.sub_type === "mental_health_score" &&
    //     questionScore >= 1
    //   ) {
    //     progressStatus =
    //       questionScore >= 10       //Peak
    //         ? (progressStatus = 5)
    //         : questionScore >= 8    //Thriving
    //         ? (progressStatus = 4)
    //         : questionScore >= 6    //Advancing
    //         ? (progressStatus = 3)
    //         : questionScore >= 3    //Progressing
    //         ? (progressStatus = 2)
    //         : (progressStatus = 1); // Starting
    //   }

    //   return {
    //     id: dataIndex + 1,
    //     name: data.heading,
    //     score: questionScore,
    //     questionCount: questionCount,
    //     progress_value: parseFloat(progressStatus) * 20, // parseFloat(progressValue).toFixed(2) || 0,
    //     progress_status: progressStatus,
    //     progress_content:
    //       scoreSubtext[data.sub_type][parseInt(progressStatus) - 1],
    //   };
    // });

    const scoreProgressObject = scoreCalculation(scoreResponse)

    const sumUserScore = Math.round(
      scoreProgressObject.reduce((sum, item) => sum + item.progress_status, 0) /
        (scoreProgressObject.length || 1)
    );

    let peakScore = 90;

    let userScore = sumUserScore * 20 - 10;
    peakScore = peakScore >= userScore ? peakScore - userScore : 90;
    let emptyScore = 100 - (userScore + peakScore);

    const userFlowScore = [
      {
        name: "User",
        value: userScore,
      },
      {
        name: "Peak",
        value: peakScore,
      },
      {
        name: "Empty",
        value: emptyScore,
      },
    ];

    setIsLoading(true);

    //API Section
    // Define Headers
    const headers = {
      client_id: REACT_APP_CLIENT_ID,
      access_token: accessToken, //REACT_APP_ACCESS_TOKEN, accessToken,
      user_timezone: timeZone, //"Asia/Kolkata",  //Intl.DateTimeFormat().resolvedOptions().timeZone
      "Content-Type": "application/json",
    };

    // Axios Request API
    try {
      const response = await axios.put(
        `${apiURL}/v2/ai-coach/program/goal`,
        { program_qus: finalResponse },
        { headers }
      );
      console.log("Response:", response);

      const apiResponse = {
        userFlowScore: userFlowScore,
        scoreProgressObject: scoreProgressObject,
      };

      setTimeout(() => {
        //setLoadingText("All set!");
        setApiResponseData(apiResponse);
        setApiRequestStatus(true);
      }, 5000);
    } catch (error) {
      console.error("Error sending PUT request:", error);

      setTimeout(() => {
        setLoadingText("Try again!");
        setApiRequestStatus(true);
        setIsFailed(true);
      }, 2000);

      // const apiResponse = {
      //   userFlowScore: userFlowScore,
      //   scoreProgressObject: scoreProgressObject,
      // };

      // setTimeout(() => {
      //   //setLoadingText("All set!");
      //   setApiResponseData(apiResponse);
      //   setApiRequestStatus(true);
      // }, 5000);

    }
  };

  //API Loader
  const [isLoading, setIsLoading] = useState(false);    // API Loading State
  const [loadingText, setLoadingText] = useState("");   // Loading Text
  const [isFailed, setIsFailed] = useState(false);      // APi Loading Failed State

  /* Lottie */
  const apiLoaderPlayOptions = {
    loop: true,
    autoplay: true,
    animationData: apiLoaderPlayData, // or animationDataUrl for URL
  };
  

  const APILoader = () => {
    return (
      <div className="preloader-section">
        <div>
          <div className="image-section">
            <div className={apiRequestStatus ? "hide" : "show"}>
              <Lottie                
                options={apiLoaderPlayOptions}
                height={267}
                width={267}
              />
            </div>            

            {/* <img
              className={apiRequestStatus ? "hide" : "show"}
              //src={preloaderPlay}
              src={require(`../assets/img/loader-play.gif`)}
              alt="Loading..."
              loading="eager"
            /> */}
            <img
              className={!apiRequestStatus ? "hide" : "show"}
              //src={preloaderPaused}
              src={require(`../assets/img/loader-pause.gif`)}
              alt="Loading..."
              loading="eager"
            />
            <span className="preloader-text">{loadingText}</span>
          </div>
          <span
            className={`loading-text ${apiRequestStatus ? "hide" : "show"}`}
          >
            Creating a plan based on <br />
            <span>your answers...</span>
          </span>
          <span
            className={`loading-text ${!isFailed ? "hide" : "show"}`}
            onClick={() => {
              setIsLoading(false);
              setApiRequestStatus(false);
              setIsFailed(false);
              setLoadingText("");
            }}
          >
            Try again <FontAwesomeIcon icon={faRefresh} />
            <br />
          </span>
        </div>
        <Button
          variant="outline-primary"
          size="sm"
          className={`all-set-button ${
            apiRequestStatus ? "" : "visibility-hidden"
          }`}
          onClick={() => {
            if (!isFailed) {
              setIsLoading(false);
              setApiRequestStatus(false);
              navigate("/score");
            } else {
              denyPlanResponse();
            }
          }}
        >
          {isFailed ? (
            <FontAwesomeIcon icon={faArrowLeft} />
          ) : (
            <FontAwesomeIcon icon={faArrowRight} />
          )}
        </Button>
      </div>
    );
  };

  /* Alert */

  const [isAlertVisible, setAlertIsVisible] = useState(false);

  function DenyAlert() {
    const closeAlert = () => {
      setAlertIsVisible(false);
    };

    const okAlert = () => {
      setAlertIsVisible(false);
      denyPlanResponse();
      // handleSaveQuestionnaire();
    };

    return (
      isAlertVisible && (
        <div className={`${isAndroid ? "android-alert" : "ios-alert"}`}>
          {/* ios-alert */}
          <div className="alert-overlay"></div>
          <div className="alert">
            <div className="inner">
              <div className="title">Exit confirmation</div>
              <div className="text">
                Are you sure you want to quit the questionnaire?
              </div>
            </div>
            <div className="button">
              <div onClick={closeAlert}>No</div>
              <div onClick={okAlert}>Yes</div>
            </div>
          </div>
        </div>
      )
    );
  }

  // const handleSaveQuestionnaire = async () => {
  //   setIsQuitLoader(true);
  //   const apiURL = REACT_APP_BASE_URL;
  //   const questionResponse = questionData
  //     .filter((val) => !val.is_remove)
  //     .map((question) => {
  //       if (question.is_boolean) {
  //         question.data.value = question.data.value === "true";
  //       }
  //       return {
  //         data: question.data,
  //         type: question.type,
  //         sub_type: question.sub_type,
  //       };
  //     });
  //   // Define Headers
  //   const headers = {
  //     client_id: REACT_APP_CLIENT_ID,
  //     access_token: accessToken, //REACT_APP_ACCESS_TOKEN, accessToken,
  //     user_timezone: timeZone, //"Asia/Kolkata",  //Intl.DateTimeFormat().resolvedOptions().timeZone
  //     "Content-Type": "application/json",
  //   };
  //   // Axios PUT Request API
  //   try {
  //     const response = await axios.put(
  //       `${apiURL}/v2/ai-coach/program/goal`,
  //       { program_qus: questionResponse },
  //       { headers }
  //     );
  //     setIsQuitLoader(false);
  //     //denyPlanResponse();
  //   } catch (error) {
  //     console.error("Error sending PUT request:", error);
  //     setIsQuitLoader(false);
  //     //denyPlanResponse();
  //   }
  // };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: androidPreloaderData, // or animationDataUrl for URL
  };

  function QuitPreloader() {
    return (
      isQuitLoader && (
        <>
          <div className="loader-overlay"></div>
          {/* <div className="loading-bar-spinner spinner">
            <div className="spinner-icon"></div>
          </div> */}
          
          <div className={`spinner center ${!isIOS ? 'hide' : ''}`}>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
          </div>
          <div className={`loader center ${isIOS ? 'hide' : ''}`}>
            <Lottie
              options={defaultOptions}
              height={200}
              width={200}
            />
          </div>
        </>
      )
    );
  }

  useEffect(() => {
    if (isAlertVisible || isQuitLoader || isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isAlertVisible, isQuitLoader, isLoading]);

  useEffect(() => {
    if (isNavigateScore) {
      //Calculate Score
      const scoreResponse = scoreData.map((question, index) => {
        const headingWiseData = questionData.filter(
          (val) => val.heading === question.heading && val.is_score
        );

        const dataScore = headingWiseData.reduce((acc, qn) => {
          const answerValue = qn.data?.value;
          const score = (qn?.options).find(
            (level) => level.value === answerValue
          )?.score;
          return acc + parseFloat(score || 0);
        }, 0);

        return {
          ...question,
          data: {
            value: dataScore,
          },
          questionCount: headingWiseData?.length,
          type: question.type,
          sub_type: question.sub_type,
        };
      });

      // const scoreProgressObject = scoreResponse.map((data, dataIndex) => {
      //   let questionScore = data.data.value || 0;
      //   let questionCount = data.questionCount || 0;

      //   let progressStatus = 1;

      //   if (data.sub_type === "activity_score" && questionScore >= 5) {
      //     progressStatus =
      //       questionScore >= 18
      //         ? (progressStatus = 5)
      //         : questionScore >= 15
      //         ? (progressStatus = 4)
      //         : questionScore >= 12
      //         ? (progressStatus = 3)
      //         : questionScore >= 10
      //         ? (progressStatus = 2)
      //         : (progressStatus = 1);
      //   } else if (data.sub_type === "nutrition_score" && questionScore >= 5) {
      //     progressStatus =
      //       questionScore >= 14
      //         ? (progressStatus = 5)
      //         : questionScore >= 12
      //         ? (progressStatus = 4)
      //         : questionScore >= 10
      //         ? (progressStatus = 3)
      //         : questionScore >= 8
      //         ? (progressStatus = 2)
      //         : (progressStatus = 1);
      //   } else if (data.sub_type === "sleep_score" && questionScore >= 5) {
      //     progressStatus =
      //       questionScore >= 18
      //         ? (progressStatus = 5)
      //         : questionScore >= 15
      //         ? (progressStatus = 4)
      //         : questionScore >= 13
      //         ? (progressStatus = 3)
      //         : questionScore >= 11
      //         ? (progressStatus = 2)
      //         : (progressStatus = 1);
      //   } else if (
      //     data.sub_type === "mental_health_score" &&
      //     questionScore >= 1
      //   ) {
      //     progressStatus =
      //       questionScore >= 10
      //         ? (progressStatus = 5)
      //         : questionScore >= 8
      //         ? (progressStatus = 4)
      //         : questionScore >= 6
      //         ? (progressStatus = 3)
      //         : questionScore >= 3
      //         ? (progressStatus = 2)
      //         : (progressStatus = 1);
      //   }

      //   return {
      //     id: dataIndex + 1,
      //     name: data.heading,
      //     score: questionScore,
      //     questionCount: questionCount,
      //     progress_value: parseFloat(progressStatus) * 20, // parseFloat(progressValue).toFixed(2) || 0,
      //     progress_status: progressStatus,
      //     progress_content:
      //       scoreSubtext[data.sub_type][parseInt(progressStatus) - 1],
      //   };
      // });
      const scoreProgressObject = scoreCalculation(scoreResponse)

      const sumUserScore = Math.round(
        scoreProgressObject.reduce(
          (sum, item) => sum + item.progress_status,
          0
        ) / (scoreProgressObject.length || 1)
      );

      let peakScore = 90;

      let userScore = sumUserScore * 20 - 10;
      peakScore = peakScore >= userScore ? peakScore - userScore : 90;
      let emptyScore = 100 - (userScore + peakScore);

      const userFlowScore = [
        {
          name: "User",
          value: userScore,
        },
        {
          name: "Peak",
          value: peakScore,
        },
        {
          name: "Empty",
          value: emptyScore,
        },
      ];

      const apiResponse = {
        userFlowScore: userFlowScore,
        scoreProgressObject: scoreProgressObject,
      };

      setApiResponseData(apiResponse); //Score Display Data
      navigate("/score");
    }
  }, [isNavigateScore]);

  return (
    <div className="container">
      <div className="Preloader-Section">{isLoading ? <APILoader /> : ""}</div>

      {isAlertVisible && <DenyAlert />}

      {isQuitLoader && <QuitPreloader />}

      <header className={`App-header ${isQuitLoader ? 'hidden' : ''}`} >
        <Button
          variant="outline-dark"
          size="sm"
          className="prev-question hide"
          // disabled={currentQuestionID === 0 ? true : false}
          onClick={() => {
            if (currentQuestionID === 0) {
              denyPlanResponse();
            } else {
              handleButtonClick(-1);
            }
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <div>
          <ProgressBar now={progressBar} label={""} />
        </div>

        <Button
          variant="outline-dark"
          size="sm"
          className="terminate-button"
          // disabled={currentQuestionID === 0 ? true : false}
          onClick={() => {
            setAlertIsVisible(true);
          }}
        >
          <FontAwesomeIcon icon={faClose} />
        </Button>
      </header>

      <div className="questionnaire-block">
        <Questions
          isVisible={isVisible}
          setNextStatus={setNextStatus}
          // currentQuestionID={currentQuestionID}
          handleCallAPI={handleCallAPI}
        />
        {/* {(accessToken) && (
          <h6>Access Token Received </h6>
        )} */}
      </div>

      <footer className={`App-footer ${isQuitLoader ? 'hidden' : ''}`}>
        <div className="button-section">
          <Button
            variant="outline-primary"
            size="sm"
            className="back-question"
            disabled={currentQuestionID === 0 ? true : false}
            onClick={() => {

              if (currentQuestionID === 0) {
                //denyPlanResponse(); //First Question
              } else {
                handleButtonClick(-1);
              }
            }}
          >
            Back
          </Button>
          <Button
            variant="outline-primary"
            size="sm"
            className="next-question"
            disabled={!nextStatus}
            onClick={() => {
              if (currentQuestionID === questionDataLength - 1) {
                handleCallAPI();
              } else {
                handleButtonClick(1);
              }
            }}
          >
            Next
          </Button>
        </div>
      </footer>
    </div>
  );
};

export default Home