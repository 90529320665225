import React from "react";

const CircleProgressBar = ({ progressValue, imageURL, type }) => {
  const normalizedProgress = Math.min(Math.max(progressValue, 0), 100);

  const circumference = 2 * Math.PI * 40;
  const strokeDashoffset = (circumference * (100 - normalizedProgress)) / 100;

  return (
    <div className={`circular-progress-bar ${type}`}>
      <svg className="circle" viewBox="0 0 100 100">
        <circle className="circle-background" cx="50" cy="50" r="40" />
        <circle
          className="circle-progress"
          cx="50"
          cy="50"
          r="40"
          style={{
            strokeDasharray: `${circumference} ${circumference}`,
            strokeDashoffset: strokeDashoffset,
          }}
        />
        <image
          className="circle-image"
          x="20"
          y="20"
          width="60"
          height="60"
          href={imageURL}
          clipPath="url(#circle-clip)"
        />
      </svg>
    </div>
  );
};

export default CircleProgressBar;
